import { combineReducers } from "redux";
import authSlice from "./authSlice";
import usersSlice from "./usersSlice";
import modalSlice from "./modalSlice";
import userMgtUsersSlice from "./userMgtUsersSlice";
import userMgtRoleSlice from "./userMgtRoleSlice";
import toast from "./toastSlice";
import feeMgtSlice from "./feeMgtSlice";
import eventSlice from "./eventSlice"
import examDietPeriodSlice from "./examDietPeriodSlice";
import examCategorySubjectSlice from "./examCategorySubjectSlice";
import examCentreSlice from "./examCentreSlice";
import examResultSlice from "./examResultSlice";
import examTimeTableSlice from "./examTimeTableSlice";
import approvalSettingsSlice from "./approvalSettingsSlice";
import approvalWorkFlowSlice from "./approvalWorkFlowSlice";
import authPermissionsSlice from "./authPermissionsSlice";
import announcementSlice from "./announcementSlice";
import searchFilterSlice from "./searchFilterSlice";
import departmentMgtSlice from "./departmentMgtSlice";
import MyDuesSlice from "./myDuesSlice";
import studentRegistrationSlice from "./studentRegistrationSlice";
import NYSCRegistrationSlice from "./NYSCRegistrationSlice";
import membershipSlice from "./membershipSlice";
import mailNotificationSlice from "./mailNotificationSlice";
import studentApplicationApprovalSlice from "./studentApplicationApprovalSlice";
import NYSCApplicationApprovalSlice from "./NYSCApplicationApprovalSlice";
import membershipUpgradeSlice from "./membershipUpgradeSlice";
import membershipManagementSlice from "./membershipManagementSlice";
import report2Slice from "./report2Slice";
import entryQualificationTypeSlice from "./entryQualificationTypeSlice";
import examMgtSlice from "./examMgtSlice";
import dashboardSlice from "./dashboardSlice";
import lookupSlice from "./lookupSlice";

const combinedSlices = combineReducers({
  auth: authSlice,
  authPermissions: authPermissionsSlice,
  users: usersSlice,
  modal: modalSlice,
  userMgtUsers: userMgtUsersSlice,
  userMgtRole:userMgtRoleSlice,
  toast: toast,
  examDietPeriod: examDietPeriodSlice,
  examCategorySubject: examCategorySubjectSlice,
  examCentre: examCentreSlice,
  examResult: examResultSlice,
  examTimeTable: examTimeTableSlice,
  approvalSettings: approvalSettingsSlice,
  approvalWorkFlow: approvalWorkFlowSlice,
  announcement: announcementSlice,
  searchFilter: searchFilterSlice,
  feeMgt: feeMgtSlice,
  event: eventSlice,
  departmentMgt: departmentMgtSlice,
  myDues: MyDuesSlice,
  studentRegistration: studentRegistrationSlice,
  NYSCRegistration: NYSCRegistrationSlice,
  membership: membershipSlice,
  mailNotification: mailNotificationSlice,
  studentApplicationApproval: studentApplicationApprovalSlice,
  NYSCApplicationApproval: NYSCApplicationApprovalSlice,
  membershipUpgrade: membershipUpgradeSlice,
  membershipManagement: membershipManagementSlice,
  report2: report2Slice,
  entryQualificationType: entryQualificationTypeSlice,
  examMgt:examMgtSlice,
  dashboard: dashboardSlice,
  lookup: lookupSlice,

});

export default combinedSlices;
