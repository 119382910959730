import * as yup from "yup";

export const signInSchema = yup.object().shape({
  email: yup.string().email("Wrong email format").required("Email is required"),
  password: yup.string().required("You must enter password"),
});

export const studentRegistrationSchema = yup.object().shape({
  email: yup.string().email("Wrong email format").matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, 'Invalid email format').required("Email is required"),
  phoneNumber: yup.string().required("Enter phone number"),
  password: yup
    .string()
    .required("Enter password")
    .min(8, "Password must be at least 8 characters long")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#.@$!%*?&_])[A-Za-z\d#.@$!%*?&_]{8,}$/,
      "Password must contain at least 1 uppercase letter, 1 lowercase letter, 1 digit, and 1 special character"
    ),
  confirmPassword: yup
    .string()
    .required("Enter confirm password")
    .oneOf([yup.ref('password'), null], 'Passwords must match'),
});

export const NYSCRegistrationSchema = yup.object().shape({
  email: yup.string().email("Wrong email format").matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, 'Invalid email format').required("Email is required"),
  phoneNumber: yup.string().required("Enter phone number"),
  password: yup
    .string()
    .required("Enter password")
    .min(8, "Password must be at least 8 characters long")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#.@$!%*?&])[A-Za-z\d#.@$!%*?&]{8,}$/,
      "Password must contain at least 1 uppercase letter, 1 lowercase letter, 1 digit, and 1 special character"
    ),
  confirmPassword: yup
    .string()
    .required("Enter confirm password")
    .oneOf([yup.ref('password'), null], 'Passwords must match'),
});

export const addUserFormSchema = yup.object().shape({
  firstName: yup.string().required("Enter first name"),
  lastName: yup.string().required("Enter last name"),
  email: yup.string().email("Wrong email format").required("Email is required"),
  department: yup.string().required("Enter department"),
  assignRole: yup.string().required("Enter role"),
  phoneNumber: yup
  .string()
  .matches(/^\d{11}$/, "Phone Number must be 11 digits")
  .required("Phone Number is required"),
  staffId: yup.string().required("Enter staff id"),
});

export const addRoleSchema = yup.object().shape({
  roleName: yup
    .string()
    .matches(/^[a-zA-Z0-9]+$/, 'Role Name must not contain spaces or special characters')
    .required("Enter Role Name"),
  description: yup.string().required("Enter Description"),
});

export const addDietSchema = yup.object().shape({
  diet: yup.string().required("Enter Diet"),
});

export const addPeriodSchema = yup.object().shape({
  examYear: yup.string().required("Select Exam Year"),
  startDate: yup.string().required("Enter Start Date"),
  endDate: yup.string().required("Enter End Date"),
});

export const addCategorySchema = yup.object().shape({
  categoryName: yup.string().required("Enter Category Name"),
  categoryCode: yup.string().required("Enter Category Code"),
  examStage: yup.string().required("Select Exam Stage"),
});

export const addSubjectSchema = yup.object().shape({
  subjectName: yup.string().required("Enter Subject Name"),
  subjectCode: yup.string().required("Enter Subject Code"),
});

export const addCentreSchema = yup.object().shape({
  centreName: yup.string().required("Enter Centre Name"),
  centreCode: yup.string().required("Enter Centre Code"),
  address: yup.string().required("Enter Address"),
});

export const uploadResultSchema = yup.object().shape({
  examYear: yup.string().required("Select Exam Year"),
  diet: yup.string().required("Select Exam Diet"),
  category: yup.string().required("Select Exam Category"),
  file: yup
    .mixed()
    .test(
      "fileType",
      "Only CSV files are allowed",
      (value) => {
        return value && value.name && value.name.endsWith('.csv');
      }
    )
    .required("Upload File is required"),
});

export const editUploadResultSchema = yup.object().shape({
  examYear: yup.string().required("Select Exam Year"),
  diet: yup.string().required("Select Exam Diet"),
  category: yup.string().required("Select Exam Category"),
  file: yup
    .mixed()
    // .required("Upload File is required")
    .test("fileType", "Only CSV files are allowed", (value) => {
      if (!value) return true; // Allow empty file
      return value && value.name && value.name.endsWith('.csv');
    }),
});

export const uploadTimeTableSchema = yup.object().shape({
  examYear: yup.string().required("Select Exam Year"),
  diet: yup.string().required("Select Exam Diet"),
  category: yup.string().required("Select Exam Category"),
  type: yup.string().required("Select Exam Type"),
  time: yup.string().required("Select Exam Time"),
  file: yup.mixed().required("Upload File is required"),
});

export const educationUploadResultSchema = yup.object().shape({
  examYear: yup.string().required("Select Exam Year"),
  diet: yup.string().required("Select Exam Diet"),
  file: yup.mixed().required("Upload File is required"),
});

export const changePasswordSchema = yup.object().shape({
  currentPassword: yup.string().required("Enter current password"),
  newPassword: yup
    .string()
    .required("Enter new password")
    .min(8, "Password must be at least 8 characters long")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      "Password must contain at least 1 uppercase letter, 1 lowercase letter, 1 digit, and 1 special character"
    ),
  confirmPassword: yup
    .string()
    .required("Enter confirm password")
    .oneOf([yup.ref('newPassword'), null], 'Passwords must match'),
});

export const addEventSchema = yup.object().shape({
  eventName: yup.string().required("Enter Event name"),
  description: yup.string().required("Enter Description"),
  registrationLink: yup.string().required("Enter Registration Link"),
  startDate: yup.string().required("pick a date"),
  endDate: yup.string().required("Pick a date"),
  eventLocation: yup.string().required("Enter the event location"),
  guestSpeaker: yup.string().required("Enter the guest speaker"),
  assignCreditPoint: yup.string().required("Select a credit point"),
});

export const addFeeTypeSchema = yup.object(0).shape({
  feeType: yup.string().required("Enter Fee Type"),
  feeCode: yup.string().required("Enter fee code"),
});

export const addFeeSchema = yup.object(0).shape({
  feeName: yup.string().required("Enter Fee Name"),
  feeType: yup.string().required("Select Fee Type"),
  // year: yup.string().required("Pick a date"),
  feeFrequency: yup.string().required("Select Frequency"),
  // amount: yup.string().required("Enter Amount"),
   memberCategory: yup.string().required("Choose a category"),
  // feeStatus: yup.string().required("Choose a status"),
});

export const SetApprovalOrderFormSchema = yup.object(0).shape({
  approvalName: yup.string().required("Select Approval Name"),
  maximumApprovalOrder: yup.string().required("Select maximum approval order"),
  membershipGrade: yup.string().required("Select membership grade"),
  approvalPoint: yup.string().required("Enter an approval point"),
});

export const AddApprovalOrderFormSchemma = yup.object(0).shape({
  approvalName: yup.string().required("Select Approval Name"),
  approvalLevel: yup.string().required("Select Approval Level"),
  assignRole: yup.string().required("Assign a role"),
  // approvalPoint: yup.string().required("Enter an approval point"),
  // membershipGrade: yup.string().required("Select membership grade"),
});

export const AddAnnouncementFormSchema = yup.object().shape({
  title: yup.string().required("Enter a title"),
  description: yup.string().required("Enter description"),
  file: yup
    .mixed()
    .required("Upload File is required")
    .test(
      "fileType",
      "Unsupported file format. Only pdf, png, jpeg files are allowed.",
      (value) => {
        if (!value) return true; // File is required, but this check prevents the test from failing if the file is not provided yet
        const supportedFormats = ["pdf", "jpeg", "jpg", "png"];
        // const fileExtension = value.name.split('.').pop();
        return supportedFormats
        // .includes(fileExtension);
      }
    ),
});

export const emailFormSchema = yup.object().shape({
  // to: yup.array().of(yup.string().email()).required("Enter the address of the person to send the mail"),
  memberCategory: yup.string().required("Choose a category"),
  subject: yup.string().required("Enter a subject"),
  message: yup.string().required("Enter a message"),
});

export const planSchema = yup.object(0).shape({
  plan: yup.string().required("Enter new plan"),
  price: yup.string().required("Choose a plan"),
  billingFrequency: yup.string().required("Slect a billing frequency"),
  memberCategory: yup.string().required("Choose a category"),
});

export const addDepartmentSchema = yup.object(0).shape({
  departmentName: yup.string().required("Enter department name"),
  departmentCode: yup.string().required("Enter department code"),
});

export const approvalReportSchema = yup.object(0).shape({
  category: yup.string().required("Select a category"),
  startDate: yup.string().required("Select a start date"),
  endDate: yup.string().required("Enter an end date"),
});
export const memberUpgradeReportSchema = yup.object(0).shape({
  startDate: yup.string().required("Select a start date"),
  endDate: yup.string().required("Enter an end date"),
});

export const memberReportSchema = yup.object(0).shape({
  memberType: yup.string().required("Choose a member"),
  // membershipLevel: yup.string().required("Select a membership level"),
  // gender: yup.string().required("Choose a gender"),
  // memberNumber: yup.string().required("Select a member number"),
  startDate: yup.string().required("Select a start date"),
  endDate: yup.string().required("Select an end date"),
});

export const statmentOfResultSchema = yup.object(0).shape({
  examNumber: yup.string().required("Enter exam number"),
  examYear: yup.string().required("Enter exam year"),
  examDiet: yup.string().required("Select exam diet"),
});

export const paymentSchema = yup.object(0).shape({
  applicantNo: yup.string().required("Enter applicant number"),
  fullName: yup.string().required("Enter full name"),
  emailAddress: yup.string().required("Enter email address"),
});

export const examApplicationSchema = yup.object(0).shape({
  examyear: yup.string().required("Select exam year"),
  examDiet: yup.string().required("Select exam diet"),
});

export const examResultSchema = yup.object(0).shape({
  examCategory: yup.string().required("select exam category"),
  examyear: yup.string().required("Select exam year"),
  examDiet: yup.string().required("Select exam diet"),
});

export const studtPersonalInformationAppFormSchema = yup.object().shape({
  passportfile: yup.mixed()
  .test("fileType", "Unsupported file format, Only png, jpg , jpeg, files are allowed,", (value) => {
    if (!value) return true; 
    const supportedFormats = ["image/jpg", "image/jpeg", "image/png"];
    return supportedFormats.includes(value.type);
  })
  .test('fileSize','File too large', value => value && value.size <= 100000)
  .required('Passport photo is required'), 
  title: yup.string().required('Title is required'),
  surname: yup.string().required('Surname is required'),
  firstname: yup.string().required('Firstname is required'),
  otherName: yup.string().required('Othername is required'),
  DOB: yup.date().required('Date of Birth is required'),
  gender: yup.string().required('Gender is required'),
  telephone: yup.string().required('Telephone number is required'),
  postCode: yup.string().required('Post code is required'),
  country: yup.string().required('Country is required'),
  stateOfOrigin: yup.string().required('State of Origin is required'),
  address: yup.string().required('Home address is required'),
});

export const NYSCPersonalInformationAppFormSchema = yup.object().shape({
  passportfile: yup.mixed()
  .test("fileType", "Unsupported file format, Only png, jpg files are allowed,", (value) => {
    if (!value) return true; 
    const supportedFormats = ["image/jpg", "image/png"];
    return supportedFormats.includes(value.type);
  })
  .test('fileSize','File too large', value => value && value.size <= 100000)
  .required('Passport photo is required'), 
  title: yup.string().required('Title is required'),
  surname: yup.string().required('Surname is required'),
  firstname: yup.string().required('Firstname is required'),
  otherName: yup.string().required('Othername is required'),
  DOB: yup.date().required('Date of Birth is required'),
  gender: yup.string().required('Gender is required'),
  telephone: yup.string().required('Telephone number is required'),
  postCode: yup.string().required('Post code is required'),
  country: yup.string().required('Country is required'),
  stateOfOrigin: yup.string().required('State of Origin is required'),
  address: yup.string().required('Home address is required'),
});

export const studtEmploymentInformationAppFormSchema = yup.object().shape({
  jobTitle: yup.string().required('Job title is required'),
  department: yup.string().required('Department is required'),
  employerName: yup.string().required('Employer name is required'),
  employerAddress: yup.string().required('Employer address is required'),
  employerPostCode: yup.string().required('Employer post code is required'),
  businessPhone: yup.string().required('Business phone number is required'),
  businessEmail: yup.string().required('Business email is required'),
  businesscountry: yup.string().required('Business country is required'),
  communicationAddress: yup.string().required('Communication address is required'),
});

export const NYSCEmploymentInformationAppFormSchema = yup.object().shape({
  jobTitle: yup.string().required('Job title is required'),
  department: yup.string().required('Department is required'),
  callUpNumber: yup.string().required('Call up number is required'),
  stateOfDeployment: yup.string().required('State of deployment is required'),
  yearOfService: yup
    .number()
    .typeError('Year of service must be a number')
    .required('Year of service is required')
    .min(new Date().getFullYear(), `Year of service cannot be less than ${new Date().getFullYear()}`),
  employerName: yup.string().required('Employer name is required'),
  employerAddress: yup.string().required('Employer address is required'),
  employerPostCode: yup.string().required('Employer post code is required'),
  businessPhone: yup.string().required('Business phone number is required'),
  businessEmail: yup.string().required('Business email is required').email('Invalid email format'),
  businesscountry: yup.string().required('Business country is required'),
  communicationAddress: yup.string().required('Communication address is required'),
});


export const studtQualificationInformationAppFormSchema = yup.object().shape({
  qualificationType: yup.string().required('Qualification type is required'),
  exemptionType: yup.string().required('Exemption type is required'),
  course: yup.string().required('Course is required'),
  schoolAttended: yup.string().required('School attended is required'),
  levelAttained: yup.string().required('Level attained is required'),
  certificatefile: yup.mixed().required('Certificate is required'),
});

export const NYSCQualificationInformationAppFormSchema = yup.object().shape({
  qualificationType: yup.string().required('Qualification type is required'),
  exemptionType: yup.string().required('Exemption type is required'),
  course: yup.string().required('Course is required'),
  schoolAttended: yup.string().required('School attended is required'),
  levelAttained: yup.string().required('Level attained is required'),
  certificatefile: yup.mixed().required('Certificate is required'),
});

export const studtMarriageRefereeInformationAppFormSchema = yup.object().shape({
  maritalStatus: yup.string().required('Marital status is required'),
  // marriageCertfile: yup.mixed().required('Marriage certificate is required'),
  RefFullName: yup.string().required('Full name is required'),
  RefMembershipNumber: yup.string().required('Membership number is required'),
  occupation: yup.string().required('Occupation is required'),
  RefPhoneNumber: yup.string().required('Phone number is required'),
  RefEmailAddress: yup.string().required('Email address is required'),
  refereeQualifications: yup.string().required('Referee qualifications is required'),
  dataProtectionCertify: yup.string().required('Data protection certify is required'),
  receiveInformation: yup.string().required('Receive information is required'),
});

export const NYSCMarriageRefereeInformationAppFormSchema = yup.object().shape({
  // maritalStatus: yup.string().required('Marital status is required'),
  // marriageCertfile: yup.mixed().required('Marriage certificate is required'),
  RefFullName: yup.string().required('Full name is required'),
  RefMembershipNumber: yup.string().required('Membership number is required'),
  occupation: yup.string().required('Occupation is required'),
  RefPhoneNumber: yup.string().required('Phone number is required'),
  RefEmailAddress: yup.string().required('Email address is required'),
  refereeQualifications: yup.string().required('Referee qualifications is required'),
  dataProtectionCertify: yup.string().required('Data protection certify is required'),
  receiveInformation: yup.string().required('Receive information is required'),
});

export const MemberUpgradeFormSchema = yup.object().shape({
  memberNo: yup.string().required('Member Number is required'),
  newMembershipUpgrade: yup.string().required('New membership upgrade is required'),
  // uploadFile: yup.string().required('upload a file'),
});

export const AddQualificationTypeSchema = yup.object(0).shape({
  name: yup.string().required("Enter Name"),
  description: yup.string().required("Enter Description"),
  examCategory: yup.string().required("Select Exam Category"),
});

export const AddApplicableExemptionSchema = yup.object(0).shape({
  examSubject: yup.string().required("Select Exam Subject"),
});

export const ClearOfflinePaymentFormSchema = yup.object(0).shape({
  amountPaid: yup.string().required("Enter Amount Paid"),
  paymentDate: yup.string().required("Enter the payment date"),
  status:yup.string().required('select a status',)
  
})