import React, { useCallback, useEffect, useState } from 'react';
import { Box, Flex,Text, Alert,AlertIcon, AlertTitle } from '@chakra-ui/react';
import Create from '../components/layout/Create';
import UserEmptyState from '../components/UserEmptyState';
import { useDispatch, useSelector} from 'react-redux';
import {setFormModal} from "../redux/slices/modalSlice";
import UserMgtRolesTable from '../components/UserMgtRolesTable';
import {fetchRoles} from '../redux/slices/userMgtRoleSlice';
import {  checkPermissions  } from '../components/CheckPermission';
import {clearsearchFilter} from "../redux/slices/searchFilterSlice";
import SortBy from '../components/SortBy';
import DotLoader from '../components/loaders/DotLoader';

const UserMgtRoles = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const errorsArray = Array.isArray(error) ? error : [error];
  const rolemgt = useSelector((state) => state?.userMgtRole);

  const getAllRoles = useCallback( async() => {
    setLoading(true);
    setError("");
    try {
      const response = await dispatch(fetchRoles());

      if (response?.payload?.name === "AxiosError"){
        setLoading(false);
        setError(response?.payload?.response?.data?.errors?.map((errors) => errors) || response?.payload?.response?.data?.error?.description || "Failed to get roles, please check your request/network");
      }
      setLoading(false);
    } catch (error) {
      setError(error?.payload?.message || "Failed to get roles, please check your network");
    }
  }, [dispatch]);

  useEffect(() => {
    dispatch(clearsearchFilter());
    getAllRoles();
  }, [dispatch, getAllRoles, rolemgt?.rolesParams]);

  const createData = {
    onClick: () => { dispatch(setFormModal({status:true, title: "Add Role", type:"addRole"})); },
    createText: "Add New Role",
  }
  const RoleData = useSelector((state) => state?.userMgtRole?.roles?.data);
  const addRolePermission = checkPermissions( 'User Management', 'Roles', 'Create', 'Granted' );
  
  return (
    <Box bg={'#FFFFFF'} py={'30px'} minH={'100vh'} px={3}>
      <Flex flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} gap={'20px'} borderBottom={'1px solid #B4B1B1'} pb={2} mb={5}>
        <Text fontSize={'18px'} fontWeight={'700'} color={'#D11A2A'}>ROLES</Text>
        {
          RoleData?.length > 0 ? (
            <Flex flexDirection={["column", "row", "row"]} alignItems={'center'} gap={[3, null, null]}>
            {
              addRolePermission? (
                <Create {...createData} />
              ): null
            }
           <SortBy show={true} />
          </Flex>
          ) : null
        }
       
      </Flex>
      {
        loading ? (
          <Flex justifyContent={'center'} alignItems={'center'}>
            <DotLoader />
          </Flex>
        ): (
          <>
            {
              RoleData?.length > 0 ? (
              <UserMgtRolesTable  />
              ) : error ? (
                <Alert status="error" className="mt-3" mb={3}>
                    <AlertIcon />
                    <AlertTitle>{errorsArray.join(', ')}</AlertTitle>
                </Alert>
                )
              : (
              <UserEmptyState createData={createData} text={'Role'} />
              )
            }
          </>
        )
      }
     
    </Box>
  )
}

export default UserMgtRoles;