import React, {useState} from 'react';
import { Form, Formik } from "formik";
import { Box, Flex, Text, Avatar, FormControl, FormErrorMessage, FormLabel, Input, Select, Button, Divider , Alert,AlertIcon, AlertTitle} from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux';
import { BiLeftArrowAlt } from "react-icons/bi";
import { useNavigate } from 'react-router-dom';
import { setFormModal } from "../redux/slices/modalSlice";
import { BASE_URL } from "../config/settings";
import httpRequest from "../utils/httpRequest";
import {fetchUsers} from "../redux/slices/userMgtUsersSlice";
import {setloadTost} from "../redux/slices/toastSlice";

const UserMgtProfile = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const RoleData = useSelector((state) => state?.lookup?.lookupRole);
    const userDetails = useSelector((state) => state.userMgtUsers.userDetails);
    const departmentData = useSelector((state) => state?.lookup?.lookupDepartment);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const errorsArray = Array.isArray(error) ? error : [error];

    const initialValues = {
        firstName: userDetails?.firstName,
        lastName: userDetails?.lastName,
        assignRole: userDetails?.role,
        email: userDetails?.email,
        department: departmentData?.filter((item) =>{return item.value.toLowerCase() === userDetails?.departmentId.toLowerCase()})[0].value || '',
        phoneNumber: userDetails?.phoneNumber,
        staffId: userDetails?.staffId,
    };
    const handleSubmit = async (values) => {
      setLoading(true);
      setError("");
      try {
        const formData = {
          departmentId: values?.department,
          staffId: values?.staffId,
          firstName: values?.firstName,
          lastName: values?.lastName,
          phoneNumber: values?.phoneNumber,
          role: values?.assignRole,
          isActive: true,
        };


        const response = await httpRequest(
          `${BASE_URL}/v2/Administrative/${userDetails?.id}/update`,
          "post",
          formData
        );
        
        if(response?.isSuccess === true){
          setLoading(false);
          dispatch(fetchUsers());
          dispatch(setFormModal({ status: false, data: null }));
          dispatch(setloadTost({ title: 'User Edited', description: `${values?.firstName} ${values?.lastName} edited successfully`, status: 'success', duration: 5000, isClosable: true, position: 'top' }));
        }else{
          setLoading(false);
          setError(response?.response?.data?.errors?.map((errors) => errors) || response?.response?.data?.error?.description || "Failed to Edit user");
        }
        
      } catch (error) {
        setError(error?.payload?.message || "Failed to edit user, please check your network");
      }finally{
        setLoading(false);
      }

    }

  return (
    <Box bg={'#FFFFFF'} py={'30px'} minH={'100vh'} px={3}>
        <Flex  alignItems={'center'} gap={'20px'} borderBottom={'1px solid #B4B1B1'} pb={4} mb={5}  cursor={'pointer'}>
            <Box bg={'#F9A36238'} rounded={'full'} p={1} onClick={() => navigate(-1)}>
              <BiLeftArrowAlt color="#D8991F" size={'20px'} />
            </Box>
            <Text fontSize={'18px'} fontWeight={'700'} color={'#222B45'} opacity={'0.5'}>User mamagement / User Profile</Text>
        </Flex>
        <Flex gap={'15px'} borderBottom={'1px solid #B4B1B1'} pb={7} mb={5}>
            <Avatar src='' size='xl' />
            <Flex flexDirection={'column'}>
                <Text color={'#384D6C'} fontWeight={'700'} fontSize={'20px'}>{userDetails?.firstName + ' ' + userDetails?.lastName}</Text>
                <Text color={'#384D6C'} fontWeight={'400'} fontSize={'20px'}>{userDetails?.role}</Text>
            </Flex>
        </Flex>
        {
          error &&(
          <Alert status="error" className="mt-3" mb={3}>
              <AlertIcon />
              <AlertTitle>{errorsArray.join(', ')}</AlertTitle>
          </Alert>
          )
        }
        <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={(values) => handleSubmit(values)}
    >
      {({ errors, touched, values, handleBlur, handleChange }) => (
        <Form>
          <Flex flexDirection={["column", "row", "row"]} alignItems={"center"} gap={3}>
            <FormControl mt={4} isInvalid={errors.firstName && touched.firstName}
                color="#121212" fontFamily={'inter'}>
              <FormLabel htmlFor='firstName' fontSize={"16px"}>First Name</FormLabel>
              <Input
                placeholder="Enter Here"
                fontSize={"small"}
                bg={'#F3F3F3'}
                name="firstName"
                focusBorderColor="#ffffff"
                borderRadius={'0'}
                borderColor={"#FFFFFF"}
                _placeholder={{ color: "#B4B1B1" }}
                _hover={{ borderColor: "#FFFFFF" }}
                type="text"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values?.firstName}
              />
              {touched.firstName && errors.firstName && (
                <FormErrorMessage>{errors.firstName}</FormErrorMessage>
              )}
            </FormControl>
            <FormControl mt={4} isInvalid={errors.lastName && touched.lastName}
                color="#121212" fontFamily={'inter'}>
              <FormLabel htmlFor='lastName' fontSize={"16px"}>Last Name</FormLabel>
              <Input
                placeholder="Enter Here "
                fontSize={"small"}
                bg={'#F3F3F3'}
                name="lastName"
                focusBorderColor="#ffffff"
                borderRadius={'0'}
                borderColor={"#FFFFFF"}
                _placeholder={{ color: "#B4B1B1" }}
                _hover={{ borderColor: "#FFFFFF" }}
                type="text"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values?.lastName}
              />
              {touched.lastName && errors.lastName && (
                <FormErrorMessage>{errors.lastName}</FormErrorMessage>
              )}
            </FormControl>
          </Flex> 
          <Flex flexDirection={["column", "row", "row"]} alignItems={"center"} gap={3}>
            <FormControl mt={4} isInvalid={errors.assignRole && touched.assignRole}
              color="#121212" fontFamily={'inter'}>
              <FormLabel htmlFor='assignRole' fontSize={"16px"}>Assign Role</FormLabel>
              <Select
                name="assignRole"
                onBlur={handleBlur}
                onChange={handleChange}
                bg={'#F3F3F3'}
                borderRadius={"0"}
                focusBorderColor="#ffffff"
                borderColor={"#FFFFFF"}
                // placeholder='Select Role'
                _hover={{ borderColor: "#FFFFFF" }}
                _placeholder={{ color: "#B4B1B1" }}
                value={values?.assignRole}
              >
                {
                  RoleData?.map((role) => (
                    <option key={role?.value} value={role?.text}>{role?.text}</option>
                  ))
                }
              </Select>
              {touched.assignRole && errors.assignRole && (
                <FormErrorMessage>{errors.assignRole}</FormErrorMessage>
              )}
            </FormControl>
            <FormControl mt={4} isInvalid={errors.email && touched.email}
              color="#121212" fontFamily={'inter'}>
              <FormLabel htmlFor='email' fontSize={"16px"}> Email Address</FormLabel>
              <Input
                type="email"
                placeholder="Enter Here"
                borderRadius={"0"}
                fontSize={"small"}
                bg={'#F3F3F3'}
                name="email"
                focusBorderColor="#ffffff"
                borderColor={"#FFFFFF"}
                _hover={{ borderColor: "#FFFFFF" }}
                _placeholder={{ color: "#B4B1B1" }}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values?.email}
                readOnly
              />
              {touched.email && errors.email && (
                <FormErrorMessage>{errors.email}</FormErrorMessage>
              )}
            </FormControl>
          </Flex>
          <Flex flexDirection={["column", "row", "row"]} alignItems={"center"} gap={3}>
            <FormControl mt={4} isInvalid={errors.phoneNumber && touched.phoneNumber}
                color="#121212" fontFamily={'inter'}>
              <FormLabel htmlFor='phoneNumber' fontSize={"16px"}>Phone Number</FormLabel>
              <Input
                placeholder="Enter Here"
                fontSize={"small"}
                bg={'#F3F3F3'}
                name="phoneNumber"
                focusBorderColor="#ffffff"
                borderRadius={'0'}
                borderColor={"#FFFFFF"}
                _placeholder={{ color: "#B4B1B1" }}
                _hover={{ borderColor: "#FFFFFF" }}
                type="tel"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values?.phoneNumber}
              />
              {touched.phoneNumber && errors.phoneNumber && (
                <FormErrorMessage>{errors.phoneNumber}</FormErrorMessage>
              )}
            </FormControl>
            <FormControl mt={4} isInvalid={errors.department && touched.department}
              color="#121212" fontFamily={'inter'}>
              <FormLabel htmlFor='department' fontSize={"16px"}>Department</FormLabel>
              <Select
                name="department"
                onBlur={handleBlur}
                onChange={handleChange}
                bg={'#F3F3F3'}
                borderRadius={"0"}
                focusBorderColor="#ffffff"
                borderColor={"#FFFFFF"}
                // placeholder='Select Department'
                _hover={{ borderColor: "#FFFFFF" }}
                _placeholder={{ color: "#B4B1B1" }}
                value={values?.department}
              >
                {
                  departmentData && departmentData?.map((department) => (
                    <option key={department?.value} value={department?.value}>{department?.text}</option>
                  ))
                }

              </Select>
              {touched.department && errors.department && (
                <FormErrorMessage>{errors.department}</FormErrorMessage>
              )}
            </FormControl>
            <FormControl mt={4} isInvalid={errors.staffId && touched.staffId}
                  color="#121212" fontFamily={'inter'}>
                <FormLabel htmlFor='staffId' fontSize={"16px"}>Staff ID</FormLabel>
                <Input
                  placeholder="Enter Here "
                  fontSize={"small"}
                  bg={'#F3F3F3'}
                  name="staffId"
                  focusBorderColor="#ffffff"
                  borderRadius={'0'}
                  borderColor={"#FFFFFF"}
                  _placeholder={{ color: "#B4B1B1" }}
                  _hover={{ borderColor: "#FFFFFF" }}
                  type="text"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values?.staffId}
                />
                {touched.staffId && errors.staffId && (
                  <FormErrorMessage>{errors.staffId}</FormErrorMessage>
                )}
              </FormControl>
          </Flex>
          <Divider border={'1px solid #D0D0D0'} mt={12}  />
          <Flex justifyContent={'center'} alignItems={"center"} gap={5}>
          <Button
              mt={10} fontSize={'14px'} fontWeight={'500'} lineHeight={'16px'}
              width={"118px"}
              background={""}
              _hover={{ background: " " }}
              color={"#D11A2A"}
              onClick={() => { navigate('/usermanagement/users') }}
            >
              Cancel
            </Button>
            <Button
              mt={10} fontSize={'14px'} fontWeight={'500'} lineHeight={'16px'}
              width={"118px"} size={'lg'}
              background={"#D11A2A"}
              _hover={{ background: " " }}
              color={"#FFFFFF"}
              type="submit"
              isLoading={loading}
              loadingText="Saving ..."
            >
              Save Changes
            </Button>
          </Flex>
        </Form>
      )}
    </Formik>
    </Box>
  )
}

export default UserMgtProfile;