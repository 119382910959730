import React, { useState } from "react";
import { Box, Menu, MenuButton, MenuItem, MenuList,  Alert,AlertIcon, AlertTitle } from "@chakra-ui/react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { setFormModal } from "../redux/slices/modalSlice";
import { checkPermissions } from "../components/CheckPermission";
import { setRoleDetails, setEditRole, fetchRoles, setUpdateRolePagination, setUpdateRolePageSize } from "../redux/slices/userMgtRoleSlice";
import { BASE_URL } from "../config/settings";
import {setloadTost} from "../redux/slices/toastSlice";
import httpRequest from "../utils/httpRequest";
import CustomTablePage2 from "./CustomTablePage2";

const UserMgtRolesTable = () => {
  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const errorsArray = Array.isArray(error) ? error : [error];
  const RoleData = useSelector((state) => state?.userMgtRole);
  const addRolePermission = checkPermissions( 'User Management', 'Roles', 'Create', 'Granted' );
  const editRolePermission = checkPermissions(
    "User Management",
    "Roles",
    "Edit",
    "Granted"
  );
  const deleteRolePermission = checkPermissions(
    "User Management",
    "Roles",
    "Delete",
    "Granted"
  );

  const handlePagination = (page) =>{
    dispatch(setUpdateRolePagination(page));
  };

  const handlePageSize = (size) => {
    dispatch(setUpdateRolePageSize(size));
  };

  const handleEdit = (item) => {
    dispatch(setEditRole(item));
    dispatch(
      setFormModal({ status: true, title: "Edit Role", type: "editRole" })
    );
  };
  const handleUpdate = (item) => {
    dispatch(setRoleDetails(item));
    if (addRolePermission === true) {
      dispatch(
        setFormModal({
          status: true,
          title: `${item?.name} role's permission`,
          type: "rolePermission",
          size: "2xl",
        })
      );
    }
  };
  const handleDeleteRole = async (item) => {
    setError('');
       try {
          const response = await httpRequest(`${BASE_URL}/v2/Role/${item?.id}/delete`,
          'post', 
        );
        // console.log(response);
        if(response?.isSuccess === true){
          setError('');
          dispatch(fetchRoles());
          dispatch(setloadTost({ title: 'Role Deleted', description: `${item?.name}  Deleted successfully`, status: 'success', duration: 5000, isClosable: true, position: 'top' }));
       }else{
          setError(response?.response?.data?.errors?.map((errors) => errors) || response?.response?.data?.error?.description || "Failed to delete Role");
        }
        
       } catch (error) {
        setError(error?.response?.message || "Failed to delete Role");
       }
  };

  const tableProps = {
    data: RoleData?.roles?.data,
    title: {
      name: "Name",
      description: "Description",
      users: " Users",
      status: "Status",
      permission: " Permission",
      Action: "",
    },
    dataKeys: [
      "name",
      "description",
      "users",
      "status",
      "permission",
      "Action",
    ],
    handlePagination,
    handlePageSize,
    itemsPerPage:RoleData?.roles?.currentPageRecord,
    totalRecord: RoleData?.roles?.totalRecord,
    pageNumber: RoleData?.rolesParams?.page,
    nextPage: RoleData?.roles?.nextPage,
    previousPage: RoleData?.roles?.previousPage,
    pageSize: RoleData?.rolesParams?.pageSize,
    transformRow: (item) => ({
      name: <Box style={{ cursor: "pointer" }}>{item?.name}</Box>,
      description: <Box style={{ cursor: "pointer" }}>{item?.description}</Box>,
      users: <Box style={{ cursor: "pointer" }}>{item?.userInRole}</Box>,
      status: item &&  (
        <Box
          style={{ cursor: "pointer" }}
          color={getStatusColor(
            item?.isActive === true ? "Active" : "Inactive"
          )}
        >
          {item?.isActive === true ? "Active" : "Inactive"}
        </Box>
      ),
      permission: item && (
        <Box
          style={{ cursor: "pointer" }}
          color={"#3064FC"}
          onClick={() => handleUpdate(item)}
        >
          View Permission
        </Box>
      ),
      Action: item &&  (
        <Menu>
          {
            !editRolePermission && !deleteRolePermission ? null : (
              <MenuButton>
                <BsThreeDotsVertical />
              </MenuButton>
            )
          }
          <MenuList>
            {editRolePermission ? (
              <MenuItem onClick={() => handleEdit(item)}> Edit Role</MenuItem>
            ) : null}
            {deleteRolePermission ? (
              <MenuItem onClick={() => handleDeleteRole(item)}> Delete Role</MenuItem>
            ) : null}
          </MenuList>
        </Menu>
      ),
    }),
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "Active":
        return "#0ADB13";
      case "Inactive":
        return "#D11A2A";
      case "Pending":
        return "#FFC34F";
      default:
        return "";
    }
  };
  
  return (
    <Box>
      {
        error ? (
        <Alert status="error" className="mt-3" mb={3}>
            <AlertIcon />
            <AlertTitle>{errorsArray.join(', ')}</AlertTitle>
        </Alert>
        ) 
        : (<CustomTablePage2 {...tableProps} />)
      }
    </Box>
  );
};

export default UserMgtRolesTable;
