import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BASE_URL } from "../../config/settings";
import httpRequest from "../../utils/httpRequest";
import { objToParams } from "../../utils/func";

const initialState = {
    events: [],
    editEvents: "",
    eventDetails: "",
    // Note : tell the backend guys to check their pagination, they're using pageSize as page and page as pageSize (they interchanged it) from their end: 
    //so when they correct it just come here and interchange their values
    eventsParams:{
     page: 1,
     pageSize: 5,
    },
    loading: false,
    error: null,
}; 

export const fetchEvent = createAsyncThunk(
    "event/fetchEvent",
    async (arg, {getState}) => {
        return httpRequest(`${BASE_URL}/v2/Event${objToParams(getState()?.event?.eventsParams)}`);
    }
);

export const createEvent = createAsyncThunk(
    "event/createEvent",
    async (arg, { rejectWithValue }) => {
        try {
            const response = await httpRequest(
                `${BASE_URL}/v2/Event`,
                "post",
                arg
            );
            if (response?.response?.data) {
                throw response;
            }
            return response;
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    }
);

const eventSlice = createSlice({
    name: "event",
    initialState,

    reducers: {
        setEditEvents: (state, { payload }) => {
            state.editEvents = payload;
        },

        setEventDetails: (state, { payload }) => {
            state.eventDetails = payload;
        },

        setUpdateEventsPagination:(state, {payload}) => {
            state.eventsParams.page = payload;
            state.events = [];
          },
          setUpdateEventsPageSize:(state, {payload}) => {
            state.eventsParams.pageSize = payload;
            state.events = [];
          },
    },

    extraReducers: (builder) => {
        // get all events
        builder.addCase(fetchEvent.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(fetchEvent.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.events = payload;
            state.error = null;
        });

        builder.addCase(fetchEvent.rejected, (state, { payload }) => {
            state.loading = false;
            state.error = payload?.error?.description;
        });

        // create events
        builder.addCase(createEvent.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(createEvent.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.error = null;
        });

        builder.addCase(createEvent.rejected, (state, { payload }) => {
            state.loading = false;
            state.error = payload?.error?.description;
        });
    }

});

export default eventSlice.reducer;
export const { setEditEvents, setEventDetails, setUpdateEventsPagination, setUpdateEventsPageSize } = eventSlice.actions;