import { Alert, AlertIcon, AlertTitle, Box, Button, Flex } from "@chakra-ui/react";
import React from "react";
import CustomTable from "./CustomTable";
import { useDispatch } from "react-redux";
import { setFormModal } from "../redux/slices/modalSlice";
import DotLoader from "../components/loaders/DotLoader";
import { setMemberUpgradeDetails } from "../redux/slices/membershipUpgradeSlice";

function PendingTable({ memberUpgradeApps, isloading, iserror }) {
  const dispatch = useDispatch();
  const handleUpdate = (item) => {
    dispatch(setMemberUpgradeDetails(item));
    dispatch(
      setFormModal({
        status: true,
        title: "Member Upgrade Details",
        type: "MembershipUpgrade",
        size: "3xl",
        data: item,
      })
    );
  };

  const tableProps = {
    data: memberUpgradeApps?.data,
    title: {
      fullName: "Full Name",
      memberNo: "Memeber NO",
      emailAddress: "Email Address",
      currentMembership: "Current Membership",
      ProposedMembership: "Proposed Membership",
      viewDetails: "",
    },
    dataKeys: [
      "fullName",
      "memberNo",
      "emailAddress",
      "currentMembership",
      "ProposedMembership",
      "viewDetails",
    ],

    transformRow: (item) => ({
      fullName: <Box style={{ cursor: "pointer" }}>{item?.fullName}</Box>,
      memberNo: <Box style={{ cursor: "pointer" }}>{item?.memberNumber}</Box>,
      emailAddress: <Box style={{ cursor: "pointer" }}>{item?.email}</Box>,
      currentMembership: (
        <Box style={{ cursor: "pointer" }}>{item?.currentMembership}</Box>
      ),
      ProposedMembership: (
        <Box style={{ cursor: "pointer" }}>{item?.proposedMembership}</Box>
      ),
      viewDetails: (
        <Button
          fontWeight={"bold"}
          fontSize={"14px"}
          bgColor={"#812773"}
          color={"white"}
          px={"10px"}
          _hover={{ bgColor: "" }}
          onClick={() => handleUpdate(item)}
        >
          View Details
        </Button>
      ),
    }),
  };

  return (
    <Box>
      {isloading ? (
        <Flex justifyContent={"center"} alignItems={"center"}>
          <DotLoader />
        </Flex>
      ) : iserror ? (
        <Alert status="error" className="mt-3" mb={3}>
          <AlertIcon />
          <AlertTitle>{iserror}</AlertTitle>
        </Alert>
      ) : (
        <CustomTable {...tableProps} />
      )}
    </Box>
  );
}

export default PendingTable;
