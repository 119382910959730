import React from 'react';
import { Button, Text, Flex, Select } from '@chakra-ui/react';
import { HiOutlineArrowLeft, HiArrowRight } from "react-icons/hi";

const PaginationMain = ({ currentPage, totalRecord, itemsPerPage, nextPage, previousPage, pageSize, handlePagination, handlePageSize }) => {

    const handlePreviousPage = () => {
      if (previousPage !== null){
        handlePagination(previousPage);
      } 
    };

    const handleNextPage = () => {
      if (nextPage !== null){
        handlePagination(nextPage);
      } 
    };

    const handleItemPerPage = (e) => {
      handlePageSize(e.target.value);
    };

    return (
        <>
          {totalRecord > 0 && (
            <Flex justifyContent="center" mt={[6, 12, 12]} alignItems="center" flexDirection={["column", "row", "row"]} gap={3}>
              <Flex alignItems="center" justifyContent="center">
                <Text fontSize={["12px", "14px", "14px"]} fontWeight="400" color="#717171" lineHeight="15px">
                  Items per page:
                </Text>
                <Select
                  w={'70px'}
                  border={'0'}
                  color={'#404040'}
                  fontSize={'14px'}
                  fontWeight={'500'}
                  lineHeight={'16px'}
                  focusBorderColor='white'
                  onChange={handleItemPerPage}
                  value={pageSize}
                >
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                </Select>
              </Flex>
              <Flex justifyContent="center" alignItems="center" fontSize={["12px", "14px", "14px"]}>
                {currentPage * itemsPerPage - itemsPerPage + 1} - {Math.min(currentPage * itemsPerPage, totalRecord)} of {totalRecord}
              </Flex>
              <Flex alignItems="center" justifyContent="center">
                <Button
                  onClick={handlePreviousPage}
                  disabled={previousPage === null}
                  bg="#FFFFFF"
                  color="#717171"
                  fontSize={["12px", "14px", "14px"]}
                  fontWeight="400"
                  _hover={{ bg: "#FFFFFF" }}
                  size="sm"
                  leftIcon={<HiOutlineArrowLeft />}
                  aria-label="Previous page"
                />
                <Button
                  onClick={handleNextPage}
                  disabled={nextPage === null}
                  bg="#FFFFFF"
                  color="#717171"
                  fontSize={["12px", "14px", "14px"]}
                  fontWeight="400"
                  _hover={{ bg: "#FFFFFF" }}
                  size="sm"
                  rightIcon={<HiArrowRight />}
                  aria-label="Next page"
                />
              </Flex>
            </Flex>
          )}
        </>
    );
};

export default PaginationMain;
