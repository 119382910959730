import React, { useEffect,  } from "react";
import { Box, Flex, Image, Text, Badge, SimpleGrid, Select, FormControl, Menu, MenuButton, MenuList, MenuItem, IconButton } from "@chakra-ui/react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchDuesManagementMemberDues, fetchDuesManagementMemberProfile, fetchDuesManagementPaymentHistory } from "../redux/slices/membershipManagementSlice";
import CustomTable from "../components/CustomTable";
import { BiLeftArrowAlt } from "react-icons/bi";
import DotLoader from '../components/loaders/DotLoader';
import {File_URL} from "../config/settings";
import { BsThreeDotsVertical } from "react-icons/bs";
import { setFormModal } from "../redux/slices/modalSlice";


const MemberDuesManagementProfile = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [searchParams] = useSearchParams();
  const memberNumber = searchParams.get("memberId");
  const memberProfileData = useSelector((state) => state?.membershipManagement);
  const duesData = useSelector((state) => state?.membershipManagement);
  
  console.log("memberProfile", memberProfileData);
  console.log("memberNumber:", memberNumber);

  useEffect(() => {
    dispatch(fetchDuesManagementMemberProfile(memberNumber));
    dispatch(fetchDuesManagementMemberDues(memberNumber));
    dispatch(fetchDuesManagementPaymentHistory(memberNumber));

  }, [dispatch, memberNumber]);

  const handleClearOfflinePayment = (item) => {
    dispatch(setFormModal({ status: true, title: "Clear Offline Payment", type: "clearOfflinePayment", size: "xl" }));
   
  };
  const tableProps = {
    data: memberProfileData?.duesManagementMemberDues?.data,
    title: {
      dueType: "Due Type",
      feeYear: "Fee Year",
      dueDate: "Due Date",
      amount: "Amount",
      status: "Status",
      viewDetails: "",
    },
    dataKeys: [
      "dueType",
      "feeYear",
      "dueDate",
      "amount",
      "status",
      "viewDetails",
    ],
    transformRow: (item) => ({
      dueType: item?.dueType,
      feeYear: item?.feeYear,
      dueDate: item?.dueDate,
      amount: item?.amount,
      status: item?.status,
      viewDetails: (
        <Menu>
          <MenuButton
            as={IconButton}
            icon={<BsThreeDotsVertical />}
            variant="ghost"
            size="sm"
            aria-label="Options"
          />
          <MenuList>
            <MenuItem 
             onClick={() => handleClearOfflinePayment(item)}
            >
              Clear Offline Payment
            </MenuItem>
            <MenuItem 
            // onClick={() => handleSendReminder(item)}
            >
              Send Reminder</MenuItem>
          </MenuList>
        </Menu>
      ),
    }),
  };

  const tablePropsPaymentHistory = {
    data: memberProfileData?.duesManagementPaymentHistory?.data,
    title: {
      date: "Date",
      payment: "Payment",
      amountPaid: "Amount Paid",
      paymentMethod: "Payment Method",
      status: "Status",
    },
    dataKeys: [
      "date",
      "payment",
      "amountPaid",
      "paymentMethod",
      "status",
    ],
    transformRow: (item) => ({
      date: item?.date && item?.date,
      payment: item?.payment,
      amountPaid: item?.amountPaid,
      paymentMethod: item?.paymentMethod,
      status: item?.status,
    }),
  };
  return (
    <Box bg="white" p={6} borderRadius="lg" boxShadow="md" width="100%">
       <Flex alignItems={'center'} gap={'20px'} borderBottom={'1px solid #B4B1B1'} pb={4} mb={5} cursor={'pointer'}>
                <Box bg={'#F9A36238'} rounded={'full'} p={1} onClick={() => navigate(-1)}>
                    <BiLeftArrowAlt color="#D8991F" size={'20px'} />
                </Box>
                <Text fontSize={'18px'} fontWeight={'700'} color={'#222B45'} opacity={'0.5'}>Member Information</Text>
            </Flex>
      <Flex alignItems="center" gap={8}>
        {/* Profile Image */}
        <Box border={"1px solid #E7DEDE"} p={10} py={"50px"}>
          <Box
            border="4px solid red"
            borderRadius="full"
            overflow="hidden"
            w="150px"
            h="150px"
            flexShrink={0}
          >
            <Image
              src={`${File_URL}${memberProfileData?.duesManagementMemberProfile?.passport}`}
              // alt="Profile Picture"
              boxSize="full"
              objectFit="cover"
              mx={"auto"}
            />
          </Box>
        </Box>

        {/* Member Information */}
        <Box border={"1px solid #E7DEDE"} flex="1" p={"12px"}>
          <Box bg="pink.50" borderRadius="md" mb={4} p={4} w={"100%"}>
            <Text fontSize="lg" fontWeight="bold" color="gray.700">
              Member Information
            </Text>
          </Box>

          <SimpleGrid pl={5}>
            <Flex gap={"150px"}>
              <Text>
                <Text as="span" color="gray.500" mr={2}>
                  Member Name
                </Text>
                <br />
                <Text as="span" fontWeight="bold">
                  {memberProfileData?.duesManagementMemberProfile?.memberName}
                </Text>
              </Text>
              <Text>
                <Text as="span" color="gray.500" mr={2}>
                  Memebership Type
                </Text>
                <br />
                <Text as="span" fontWeight="bold">
                  {memberProfileData?.duesManagementMemberProfile?.membershipType}
                </Text>
              </Text>
              <Badge
                fontSize="lg"
                bgColor="#005E39"
                color={'white'}
                px={6}
                py={3}
                borderRadius="md"
                textAlign="center"
                // ml={"90px"}
              >
                {memberProfileData?.duesManagementMemberProfile?.totalDueAmount}
              </Badge>
            </Flex>
            <Flex gap={"120px"} pt={10}>
              <Text>
                <Text as="span" color="gray.500" mr={2}>
                  Last Payment Date
                </Text>
                <br />
                <Text as="span" fontWeight="bold">
                  {memberProfileData?.duesManagementMemberProfile?.lastPaymentDate}
                </Text>
              </Text>
              <Text>
                <Text as="span" color="gray.500" mr={2}>
                  Last Payment Amount
                </Text>
                <br />
                <Text as="span" fontWeight="bold">
                  {memberProfileData?.duesManagementMemberProfile?.lastPaymentAmount}
                </Text>
              </Text>
            </Flex>
          </SimpleGrid>
        </Box>
      </Flex>
      <Box  minH={'100vh'} bgColor={'#FFF'}>
      <Flex flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} gap={'20px'} px={'20px'} borderBottom={'1px solid #B4B1B1'} pb={2} mb={5}>
        <Text fontSize={'20px'} fontWeight={'700'} color={'#D11A2A'} opacity={'50%'} >Dues</Text>
        <Flex flexDirection={["column", "column", "row"]} alignItems={'center'} gap={[null, null, null]}>
          <Flex alignItems={'center'}>
            <FormControl w={'100px'}>
              <Select placeholder='sort by' border={'0'} color={'#404040'} fontSize={'14px'} fontWeight={'500'} lineHeight={'16px'} focusBorderColor='white'>
                <option value='option1'>Option 1</option>
                <option value='option2'>Option 2</option>
                <option value='option3'>Option 3</option>
              </Select>
            </FormControl>
          </Flex>
          <svg xmlns="http://www.w3.org/2000/svg" width="19" height="20" viewBox="0 0 19 20" fill="none">
            <g clip-path="url(#clip0_26_2208)">
              <path d="M7.6 0.344238C6.365 0.344238 5.32 1.14224 4.921 2.24424H0V4.14424H4.921C5.3105 5.24624 6.3555 6.04424 7.6 6.04424C9.1675 6.04424 10.45 4.76174 10.45 3.19424C10.45 1.62674 9.1675 0.344238 7.6 0.344238ZM12.35 2.24424V4.14424H19V2.24424H12.35ZM12.35 6.99424C11.115 6.99424 10.07 7.79224 9.671 8.89424H0V10.7942H9.671C10.0605 11.8962 11.1055 12.6942 12.35 12.6942C13.9175 12.6942 15.2 11.4117 15.2 9.84424C15.2 8.27674 13.9175 6.99424 12.35 6.99424ZM17.1 8.89424V10.7942H19V8.89424H17.1ZM4.75 13.6442C3.515 13.6442 2.47 14.4422 2.071 15.5442H0V17.4442H2.071C2.4605 18.5462 3.5055 19.3442 4.75 19.3442C6.3175 19.3442 7.6 18.0617 7.6 16.4942C7.6 14.9267 6.3175 13.6442 4.75 13.6442ZM9.5 15.5442V17.4442H19V15.5442H9.5Z" fill="#404040" />
            </g>
            <defs>
              <clipPath id="clip0_26_2208">
                <rect width="19" height="19" fill="white" transform="translate(0 0.344238)" />
              </clipPath>
            </defs>
          </svg>
        </Flex>
      </Flex>
      {
        duesData?.loading ? (
          <Flex justifyContent={'center'} alignItems={'center'}>
            <DotLoader />
          </Flex>
        ) : (
          <CustomTable {...tableProps} />
        )
      }
    </Box>
    <Box  minH={'100vh'} bgColor={'#FFF'}>
      <Flex flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} gap={'20px'} px={'20px'} borderBottom={'1px solid #B4B1B1'} pb={2} mb={5}>
        <Text fontSize={'20px'} fontWeight={'700'} color={'#D11A2A'} opacity={'50%'} >Payment History</Text>
        <Flex flexDirection={["column", "column", "row"]} alignItems={'center'} gap={[null, null, null]}>
          <Flex alignItems={'center'}>
            <FormControl w={'100px'}>
              <Select placeholder='sort by' border={'0'} color={'#404040'} fontSize={'14px'} fontWeight={'500'} lineHeight={'16px'} focusBorderColor='white'>
                <option value='option1'>Option 1</option>
                <option value='option2'>Option 2</option>
                <option value='option3'>Option 3</option>
              </Select>
            </FormControl>
          </Flex>
          <svg xmlns="http://www.w3.org/2000/svg" width="19" height="20" viewBox="0 0 19 20" fill="none">
            <g clip-path="url(#clip0_26_2208)">
              <path d="M7.6 0.344238C6.365 0.344238 5.32 1.14224 4.921 2.24424H0V4.14424H4.921C5.3105 5.24624 6.3555 6.04424 7.6 6.04424C9.1675 6.04424 10.45 4.76174 10.45 3.19424C10.45 1.62674 9.1675 0.344238 7.6 0.344238ZM12.35 2.24424V4.14424H19V2.24424H12.35ZM12.35 6.99424C11.115 6.99424 10.07 7.79224 9.671 8.89424H0V10.7942H9.671C10.0605 11.8962 11.1055 12.6942 12.35 12.6942C13.9175 12.6942 15.2 11.4117 15.2 9.84424C15.2 8.27674 13.9175 6.99424 12.35 6.99424ZM17.1 8.89424V10.7942H19V8.89424H17.1ZM4.75 13.6442C3.515 13.6442 2.47 14.4422 2.071 15.5442H0V17.4442H2.071C2.4605 18.5462 3.5055 19.3442 4.75 19.3442C6.3175 19.3442 7.6 18.0617 7.6 16.4942C7.6 14.9267 6.3175 13.6442 4.75 13.6442ZM9.5 15.5442V17.4442H19V15.5442H9.5Z" fill="#404040" />
            </g>
            <defs>
              <clipPath id="clip0_26_2208">
                <rect width="19" height="19" fill="white" transform="translate(0 0.344238)" />
              </clipPath>
            </defs>
          </svg>
        </Flex>
      </Flex>
      {
        duesData?.loading ? (
          <Flex justifyContent={'center'} alignItems={'center'}>
            <DotLoader />
          </Flex>
        ) : (
          <CustomTable {...tablePropsPaymentHistory} />
        )
      }
    </Box>

    </Box>
  );
};

export default MemberDuesManagementProfile;
