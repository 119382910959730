
import React, { useCallback, useEffect, useState } from 'react';
import { Box, Flex,Text, Menu, MenuButton, MenuItem, MenuList, Alert,AlertIcon, AlertTitle } from '@chakra-ui/react';
import Create from '../components/layout/Create';
import {BsThreeDotsVertical} from 'react-icons/bs';
import { useDispatch, useSelector} from 'react-redux';
import {setFormModal} from "../redux/slices/modalSlice";
import CustomTable from '../components/CustomTable';
import SortBy from '../components/SortBy';
import DownloadBtn from '../components/DownloadBtn';
import { checkPermissions} from '../components/CheckPermission';
import {fetchExamCategory} from "../redux/slices/examCategorySubjectSlice";
import {fetchExamDiet} from "../redux/slices/examDietPeriodSlice";
import {fetchExamTimeTable, setEditExamTimeTable, setExamTimeTableDetails } from "../redux/slices/examTimeTableSlice";
import {clearsearchFilter} from "../redux/slices/searchFilterSlice";
import DotLoader from '../components/loaders/DotLoader';
import { BASE_URL } from "../config/settings";
import {setloadTost} from "../redux/slices/toastSlice";
import httpRequest from "../utils/httpRequest";

const ExamTimetable = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const errorsArray = Array.isArray(error) ? error : [error];

  const getAllExamTimeTable = useCallback( async() => {
    setLoading(true);
    setError("");
    try {
      const response = await dispatch(fetchExamTimeTable());
      // console.log('examTimeTable response', response);
      if (response?.payload?.name === "AxiosError"){
        setLoading(false);
        setError(response?.payload?.response?.data?.errors?.map((errors) => errors) || response?.payload?.response?.data?.error?.description || "Failed to get Exam time table, please check your request/network");
      }
      setLoading(false);
      
    } catch (error) {
      setError(error?.payload?.message || "Failed to get Exam time table, please check your network");
    }
  }, [dispatch]);

  useEffect(() => {
    dispatch(clearsearchFilter());
    getAllExamTimeTable();
    dispatch(fetchExamCategory(1000));
    dispatch(fetchExamDiet());
  }, [dispatch, getAllExamTimeTable]);

  const addTimeTablePermission = checkPermissions( 'Exam Setup', 'Exam TimeTable', 'Create', 'Granted' );
  const editTimeTablePermission = checkPermissions( 'Exam Setup', 'Exam TimeTable', 'Edit', 'Granted' );
  const deleteTimeTablePermission = checkPermissions( 'Exam Setup', 'Exam TimeTable', 'Delete', 'Granted' );
  const examTimeTableData = useSelector((state) => state?.examTimeTable?.examTimeTable?.data);
  // console.log("examTimeTable", examTimeTableData)

  const createData = {
    onClick: () => { dispatch(setFormModal({status:true, title: "Upload Timetable ", type:"uploadTimetable"})); },
    createText: "Upload New  ",
  }
  const handleUpdate = (item) => {
    dispatch(setExamTimeTableDetails(item));
    dispatch(setFormModal({status:true, title: "Exam Time Table Details", type:"examTimeTableDetails"}));
  }

  const handleEdit = (item) => {
    dispatch(setEditExamTimeTable(item));
    dispatch(setFormModal({status:true, title: "Edit Timetable", type:"editTimetable"}));
  }

  const handleDelete = async (item) => {
    // console.log(item);
    setError('');
   try {
      const response = await httpRequest(`${BASE_URL}/v2/TimeTable/${item?.id}/delete`,
      'post', 
    );
    // console.log(response);
    if(response?.isSuccess === true){
      setError('');
      dispatch(fetchExamTimeTable());
      dispatch(setloadTost({ title: 'Time Table Deleted', description: `${item?.examYear} ${item?.examDiet} ${item?.examCategory}  Deleted successfully`, status: 'success', duration: 5000, isClosable: true, position: 'top' }));
   }else{
      setError(response?.response?.data?.errors?.map((errors) => errors) || response?.response?.data?.error?.description || "Failed to delete Time Table");
    }
    
   } catch (error) {
    setError(error?.response?.message || "Failed to delete Time Table");
   }

}

  const tableProps = {
    data: examTimeTableData,
    title: {
      examYear: "Exam Year",
      examDiet: "Exam Diet",
      category:"Exam Category",
      // examType: "Exam Type",
      examPeriod:" ",
      Action:'',
    },

    dataKeys: [
      "examYear",
      "examDiet",
      "category",
      // "examType",
      "examPeriod",
      "Action",
    ],
    
    transformRow: (item) => ({
      examYear: item && <Box style={{cursor:'pointer'}}>{item?.examYear}</Box>,
      examDiet: item && <Box style={{cursor:'pointer'}}>{item?.examDiet}</Box>,
      category: item && <Box style={{cursor:'pointer'}}>{item?.examCategory}</Box>,
      // examType: <Box style={{cursor:'pointer'}}>{item?.examType}</Box>,
      examPeriod: item && <Box style={{cursor:'pointer'}} color={'#3064FC'} textDecoration={'underline'} fontSize={'14px'} fontWeight={'500'} lineHeight={'16px'} onClick={() => handleUpdate(item)}>View File</Box>,
      Action: item && (
        <Menu>
          {
            !editTimeTablePermission && !deleteTimeTablePermission ? null : (
              <MenuButton>
                <BsThreeDotsVertical />
              </MenuButton>
            )
          }
              
          <MenuList >
            {
              editTimeTablePermission ? (
                <MenuItem onClick={() => handleEdit(item)}>Edit </MenuItem>
              ) : null
            }
            {
              deleteTimeTablePermission ? (
                <MenuItem onClick={() => handleDelete(item)} > Delete </MenuItem>
              ) : null
            }
          </MenuList>
        </Menu>
      )
  }),
  };

  return (
     <Box bg={'#FFFFFF'} py={'30px'} minH={'100vh'} px={3}>
      <Flex flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} gap={'20px'} borderBottom={'1px solid #B4B1B1'} pb={2} mb={5}>
        <Text fontSize={'18px'} fontWeight={'700'} color={'#D11A2A'}> Upload Exam Timetable</Text>
        <Flex flexDirection={["column", "column", "row"]} alignItems={'center'} gap={[3, null, null]}>
          <Text cursor={'pointer'} color={'#D11A2A'} fontSize={'12px'} fontWeight={'600'} lineHeight={'16px'} textDecoration={'underline'}>DOWNLOAD TEMPLATE HERE</Text>
          {
            addTimeTablePermission ? (
              <Create {...createData} />
            ) : null
          }
          <SortBy />
          <DownloadBtn />
        </Flex>
      </Flex>
        {
          loading ? (
            <Flex justifyContent={'center'} alignItems={'center'}>
              <DotLoader />
            </Flex>
          ): (
            <>
              {
              error ? (
                <Alert status="error" className="mt-3" mb={3}>
                  <AlertIcon />
                  <AlertTitle>{errorsArray.join(', ')}</AlertTitle>
                </Alert>
                ) 
                : (<CustomTable {...tableProps} />)
              }
            </>
          )
        }
        
    </Box>
  )
}

export default ExamTimetable;