import React, { useEffect, useCallback, useState } from 'react';
import { Box, Flex, Text, Button, Menu, MenuButton, MenuItem, MenuList, Alert,AlertIcon, AlertTitle  } from '@chakra-ui/react';
import { BiLeftArrowAlt } from "react-icons/bi";
import { PiExport } from "react-icons/pi";
import {BsThreeDotsVertical} from 'react-icons/bs';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {setFormModal} from "../redux/slices/modalSlice";
import Create from '../components/layout/Create';
import CustomTable from './CustomTable';
import SortBy from './SortBy';
import { checkPermissions} from '../components/CheckPermission';
import {fetchExamPeriod, setEditExamPeriod} from "../redux/slices/examDietPeriodSlice";
import {clearsearchFilter} from "../redux/slices/searchFilterSlice";
import DotLoader from './loaders/DotLoader';
import httpRequest from "../utils/httpRequest";
import { BASE_URL } from "../config/settings";
import { setloadTost } from '../redux/slices/toastSlice';


const ExamPeriod = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const dietId = searchParams.get("dietId");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const errorsArray = Array.isArray(error) ? error : [error];
    const examDietDetails = useSelector((state) => state?.examDietPeriod?.examDietDetails);

    const getAllExamPeriod = useCallback( async() => {
        setLoading(true);
        setError("");
    try {
      const response = await dispatch(fetchExamPeriod(dietId));
      if (response?.payload?.name === "AxiosError"){
        setLoading(false);
        setError(response?.payload?.response?.data?.errors?.map((errors) => errors) || response?.payload?.response?.data?.error?.description || "Failed to get Exam Period, please check your request/network");
      }
      setLoading(false);
      
    } catch (error) {
      setError(error?.payload?.message || "Failed to get Exam Period, please check your network");
    }
    }, [dispatch, dietId]);

    useEffect(() => {
        dispatch(clearsearchFilter());
        getAllExamPeriod();
    }, [dispatch, getAllExamPeriod]);

    const addPeriodPermission = checkPermissions( 'Exam Setup', 'Exam Diet', 'Create', 'Granted' );
    const editPeriodPermission = checkPermissions( 'Exam Setup', 'Exam Diet', 'Edit', 'Granted' );
    const deletePeriodPermission = checkPermissions( 'Exam Setup', 'Exam Diet', 'Delete', 'Granted' );
    const examPeriodData = useSelector((state) => state?.examDietPeriod?.examPeriod?.data);

    const createData = {
        onClick: () => { dispatch(setFormModal({status:true, title: "Create Exam Period", type:"addPeriod"})); },
        createText: "Add Period",
    }

    const handleEdit = (item) => {
        dispatch(setEditExamPeriod(item));
        dispatch(setFormModal({status:true, title: "Edit Period", type:"editPeriod"}));
    }

    const handleDelete = async (item) => {
      setError('');
     try {
        const response = await httpRequest(`${BASE_URL}/v2/ExamDiet/period/${item?.id}/delete`,
        'post', 
      );
      console.log('response', response)
      if(response?.isSuccess === true){
        setError('');
        dispatch(fetchExamPeriod(dietId));
        dispatch(setloadTost({ title: 'Exam Period Deleted', description: `Deleted successfully`, status: 'success', duration: 5000, isClosable: true, position: 'top' }));
     }else{
        setError(response?.response?.data?.errors?.map((errors) => errors) || response?.response?.data?.error?.description || "Failed to delete Exam Period");
      }
      
     } catch (error) {
      setError(error?.response?.message || "Failed to delete Exam Period");
     }
  
  }

    const tableProps = {
        data: examPeriodData,
        title: {
            examYear: "Exam Year",
            startDate:"Start Date",
            endDate:"End Date",
            Action:'',
        },
        dataKeys: [
          "examYear",
          "startDate",
          "endDate",
          "Action",
        ],
        transformRow: (item) => ({
            examYear: <Box style={{cursor:'pointer'}}>{item?.year}</Box>,
            startDate: <Box style={{cursor:'pointer'}}>{item?.startDate}</Box>,
            endDate: <Box style={{cursor:'pointer'}}>{item?.endDate}</Box>,
            Action: item && (
                <Menu>
                  {
                    !editPeriodPermission && !deletePeriodPermission? null :  (
                    <MenuButton>
                      <BsThreeDotsVertical />
                    </MenuButton>
                    )
                  }
                  <MenuList >
                    {
                    editPeriodPermission? (
                        <MenuItem onClick={() => handleEdit(item)} >Edit </MenuItem>
                    ) : null
                    }
                    {
                    deletePeriodPermission? (
                        <MenuItem onClick={() => handleDelete(item)}> Delete </MenuItem>
                    ) : null
                    }
                  </MenuList>
                </Menu>
              )
        }),
    };

  return (
    <Box bg={'#FFFFFF'} py={'30px'} minH={'100vh'} px={3}>
        <Flex justifyContent={'space-between'} alignItems={'center'} gap={'20px'} borderBottom={'1px solid #B4B1B1'} pb={4} mb={5} cursor={'pointer'}>
            <Flex alignItems={'center'} gap={'20px'}  >
                <Box bg={'#F9A36238'} rounded={'full'} p={1} onClick={() => navigate(-1)}>
                    <BiLeftArrowAlt color="#D8991F" size={'20px'} />
                </Box>
                <Text fontSize={'18px'} fontWeight={'700'} color={'#222B45'} opacity={'0.5'}>Exam Period / {examDietDetails?.name}</Text>
            </Flex>
            <Flex flexDirection={["column", "column", "row"]} alignItems={'center'} gap={[3, null, null]}>
                {
                    addPeriodPermission? (
                        <Create {...createData} />
                    ) : null
                }
                <SortBy />
                <Button leftIcon={<PiExport style={{width: "15px", height: "15px"}} />} bg={'transparent'} _hover={{backgroundColor:"transparent"}} _active={{backgroundColor:"transparent"}} color={'#404040'}
                    fontFamily={'inter'} fontSize={'12px'} fontWeight={'500'} lineHeight={'24px'} letterSpacing={'0.4px'} 
                >
                    Export
                </Button>
            </Flex>
        </Flex>
        {
          loading ? (
            <Flex justifyContent={'center'} alignItems={'center'}>
              <DotLoader />
            </Flex>
          ): (
            <>
              {
                error ? (
                    <Alert status="error" className="mt-3" mb={3}>
                        <AlertIcon />
                        <AlertTitle>{errorsArray.join(', ')}</AlertTitle>
                    </Alert>
                ) 
                : (<CustomTable {...tableProps} />)
              }
            </>
          )
        }
    </Box>
  )
}

export default ExamPeriod;