import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BASE_URL } from "../../config/settings";
import httpRequest from "../../utils/httpRequest";


const initialState = {
    loading: false,
    error: null,
    lookupRole: [],
    lookupDepartment: [],
    lookupFee: [],
    lookupEvent: [],
    lookupApprovalSetup: [],
};


export const fetchLookupRole = createAsyncThunk(
    "event/fetchLookupRole",
    async () => {
        return httpRequest(`${BASE_URL}/v2/Lookup/Role`);
    }
);

export const fetchLookupDepartment = createAsyncThunk(
    "event/fetchLookupDepartment",
    async () => {
        return httpRequest(`${BASE_URL}/v2/Lookup/Department`);
    }
);

export const fetchLookupFee = createAsyncThunk(
    "event/fetchLookupFee",
    async () => {
        return httpRequest(`${BASE_URL}/v2/Lookup/Fee`);
    }
);

export const fetchLookupEvent = createAsyncThunk(
    "event/fetchLookupEvent",
    async () => {
        return httpRequest(`${BASE_URL}/v2/Lookup/Event`);
    }
);

export const fetchLookupApprovalSetup = createAsyncThunk(
    "event/fetchLookupApprovalSetup",
    async () => {
        return httpRequest(`${BASE_URL}/v2/Lookup/ApprovalSetup`);
    }
);


const lookupSlice = createSlice({
    name: "lookup",
    initialState,
    extraReducers: (builder) => {
        // get lookup role
        builder.addCase(fetchLookupRole?.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(fetchLookupRole?.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.lookupRole = payload;
            state.error = null;
        });

        builder.addCase(fetchLookupRole?.rejected, (state, { payload }) => {
            state.loading = false;
            state.error = payload;
        });

        // get lookup department
        builder.addCase(fetchLookupDepartment?.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(fetchLookupDepartment?.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.lookupDepartment = payload;
            state.error = null;
        });

        builder.addCase(fetchLookupDepartment?.rejected, (state, { payload }) => {
            state.loading = false;
            state.error = payload;
        });

        // get lookup fee
        builder.addCase(fetchLookupFee?.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(fetchLookupFee?.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.lookupFee = payload;
            state.error = null;
        });

        builder.addCase(fetchLookupFee?.rejected, (state, { payload }) => {
            state.loading = false;
            state.error = payload;
        });

        // get lookup event
        builder.addCase(fetchLookupEvent?.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(fetchLookupEvent?.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.lookupEvent = payload;
            state.error = null;
        });

        builder.addCase(fetchLookupEvent?.rejected, (state, { payload }) => {
            state.loading = false;
            state.error = payload;
        });

        // get lookup approval setup
        builder.addCase(fetchLookupApprovalSetup?.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(fetchLookupApprovalSetup?.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.lookupApprovalSetup = payload;
            state.error = null;
        });

        builder.addCase(fetchLookupApprovalSetup?.rejected, (state, { payload }) => {
            state.loading = false;
            state.error = payload;
        });
    }
});


export default lookupSlice.reducer;