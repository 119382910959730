import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Select,
  Text,
} from "@chakra-ui/react";
import { Form, Formik } from "formik";
import React from "react";
// import { paymentSchema } from "../utils/formValidationSchema";
import PaymentReport from "../components/PaymentReport";
import { useDispatch, useSelector } from "react-redux";
import { fetchPaymentTypeReport } from "../redux/slices/report2Slice";
import { setloadTost } from "../redux/slices/toastSlice";

function PaymentTypeReport() {
  const dispatch = useDispatch();
  const paymentTypeData = useSelector((state) => state?.report2);
  console.log("paymentTypeData", paymentTypeData);
  const initialValues = {
    feeType: "",
    startDate: "",
    endDate: "",
  };

  const handleSubmit = async (values, actions) => {
    const response = await dispatch(
      fetchPaymentTypeReport({
        feeType: values?.feeType,
        startDate: values?.startDate,
        endDate: values?.endDate,
      })
    );
    if (response?.payload?.isSuccess === false) {
      dispatch(
        setloadTost({
          title: "Error",
          description:
            response?.payload?.errors?.map((errors) => errors) ||
            response?.payload?.error?.description ||
            "Failed to get report",
          status: "warning",
          duration: 5000,
          isClosable: true,
          position: "top",
        })
      );
    } else {
      dispatch(
        setloadTost({
          title: "Success",
          description: `report generated successfully`,
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "top",
        })
      );
      actions.resetForm();
    }
  };
  return (
    <>
      <Box
        bgColor={"white"}
        height={["550px", "", "270px"]}
        px={"30px"}
        py={"20px"}
        pb={"10px"}
      >
        <Text color={"#3A3541DE"} fontSize={"16px"} fontWeight={500}>
          Search Filters
        </Text>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          // validationSchema={paymentSchema}
          onSubmit={(values) => handleSubmit(values)}
        >
          {({ errors, touched, values, handleBlur, handleChange }) => (
            <Form>
              <Flex
                flexDirection={["column", "column", "row"]}
                alignItems={"center"}
                gap={3}
              >
                <FormControl
                  mt={4}
                  isInvalid={errors.feeType && touched.feeType}
                  color="#121212"
                >
                  <FormLabel htmlFor="feeType" fontSize={"16px"}>
                    Fee Type
                  </FormLabel>
                  <Select
                    placeholder="Select Member Category"
                    height={"52px"}
                    name="feeType"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    bg={"#F3F3F3"}
                    borderRadius={"0"}
                    focusBorderColor="#ffffff"
                    borderColor={"#FFFFFF"}
                    _hover={{ borderColor: "#FFFFFF" }}
                    _placeholder={{ color: "#B4B1B1" }}
                    value={values?.feeType}
                  >
                    <option value="Registration">Registration</option>
                    <option value="Examination">Examination</option>
                    <option value="Upgrade">Upgrade</option>
                    <option value="Event">Event</option>
                    <option value="Subscription">Subscription</option>
                    <option value="SubRenewal">SubRenewal</option>
                  </Select>
                  {touched.feeType && errors.feeType && (
                    <FormErrorMessage>{errors.feeType}</FormErrorMessage>
                  )}
                </FormControl>
                <FormControl
                  mt={4}
                  isInvalid={errors.startDate && touched.startDate}
                  color="#121212"
                  fontFamily={"inter"}
                >
                  <FormLabel htmlFor="startDate" fontSize={"16px"}>
                    Start Date
                  </FormLabel>
                  <Input
                    height={"52px"}
                    type="date"
                    placeholder="Pick a Date"
                    borderRadius={"0"}
                    fontSize={"small"}
                    bg={"#F3F3F3"}
                    name="startDate"
                    focusBorderColor="#ffffff"
                    borderColor={"#FFFFFF"}
                    _hover={{ borderColor: "#FFFFFF" }}
                    _placeholder={{ color: "#B4B1B1" }}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values?.startDate}
                  />
                  {touched.startDate && errors.startDate && (
                    <FormErrorMessage>{errors.startDate}</FormErrorMessage>
                  )}
                </FormControl>
                <FormControl
                  mt={4}
                  isInvalid={errors.endDate && touched.endDate}
                  color="#121212"
                  fontFamily={"inter"}
                >
                  <FormLabel htmlFor="endDate" fontSize={"16px"}>
                    End Date
                  </FormLabel>
                  <Input
                    height={"52px"}
                    type="date"
                    placeholder="Pick a Date"
                    borderRadius={"0"}
                    fontSize={"small"}
                    bg={"#F3F3F3"}
                    name="endDate"
                    cursor={"pointer"}
                    focusBorderColor="#ffffff"
                    borderColor={"#FFFFFF"}
                    _hover={{ borderColor: "#FFFFFF" }}
                    _placeholder={{ color: "#B4B1B1" }}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values?.endDate}
                  />
                  {touched.endDate && errors.endDate && (
                    <FormErrorMessage>{errors.endDate}</FormErrorMessage>
                  )}
                </FormControl>
              </Flex>
              <Flex
                flexDirection={["column", "column", "row"]}
                alignItems={"center"}
                gap={3}
              >
                <FormControl></FormControl>
                <FormControl></FormControl>
                <FormControl></FormControl>
                <FormControl></FormControl>
                <FormControl></FormControl>
                <FormControl>
                  <Button
                    mt={10}
                    fontSize={"14px"}
                    fontWeight={"500"}
                    lineHeight={"16px"}
                    width={"118px"}
                    background={"#D11A2A"}
                    _hover={{ background: " " }}
                    color={"#FFFFFF"}
                    type="submit"
                    isLoading={paymentTypeData?.loading}
                    loadingText="fetching ..."
                  >
                    Fetch Report
                  </Button>
                </FormControl>
              </Flex>
            </Form>
          )}
        </Formik>
      </Box>
      {paymentTypeData?.paymentType?.data ? (
        <PaymentReport
          paymentTypeData={paymentTypeData?.paymentType?.data}
        />
      ) : null}
    </>
  );
}

export default PaymentTypeReport;
